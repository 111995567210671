
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'FlowStepList',
  props: {
    startStep: {
      type: [Number, String],
      default: 1,
    },
  },
  emits: ['update:current-step'],
  setup(props, context) {
    const route = useRoute()
    const currentStep = ref(+(route.query.step || props.startStep))
    const onStepChange = (value: number) => {
      currentStep.value = value
      context.emit('update:current-step', value)
    }

    return {
      currentStep,
      onStepChange,
    }
  },
})
