import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8b442a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flow-step-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {
      currentStep: _ctx.currentStep,
      onStepChange: _ctx.onStepChange
    }, undefined, true)
  ]))
}